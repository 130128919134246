import { Alert, AppBar, Box, CircularProgress, Container, CssBaseline, Divider, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


import MenuIcon from '@mui/icons-material/Menu';

import packageJson from '../../package.json';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Tableau = ({ conf }) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [backendData, setBackendData] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    const [loading, setLoading] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const query = useQuery();
    let view = query.get('v');

    let authorizationHeader = `Bearer ${authState.accessToken?.accessToken}`;

    useEffect(() => {

        setLoading(true);
        setErrorMsg(null);

        fetch(`${conf.app.backendApiUrl}/ticket${view ? '?v=' + view : ''}`, {
            headers: { 'Authorization': authorizationHeader }
        })
            .then(res => {
                if (res.ok)
                    return res.json();
                else
                    throw new Error(res.status);
            })
            .then(data => setBackendData(data))
            .catch(err => setErrorMsg('Failed to fetch backend data ' + err))
            .finally(() => setLoading(false));
    }, [conf, view, authorizationHeader]);

    
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        handleClose();
        oktaAuth.signOut();
    };

    if (loading) return <Container className='App' fixed><CircularProgress /><p>chargement...</p></Container>;

    return <Container maxWidth="xl" fixed>

        <CssBaseline />

        <AppBar position="static">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={handleMenu}
                >
                    <MenuIcon />
                </IconButton>

                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>Version front: {packageJson.version}</MenuItem>
                    <MenuItem onClick={handleClose}>Version back: {backendData?.backendVersion}</MenuItem>
                    <Divider></Divider>
                    <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
                </Menu>

                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Welcome {authState.idToken?.claims?.name}
                </Typography>

            </Toolbar>
        </AppBar>


        {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        <Box sx={{ height: '100vh' }}>
            {backendData?.tableauLink && <iframe src={backendData.tableauLink} title='Tableau' width={'100%'} height={'100%'}></iframe>}
        </Box>


    </Container>

};

export default Tableau;