import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

const OktaSignInWidget = ({ conf, onSuccess, onError }) => {
  const widgetRef = useRef();
  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    let widgetConf = conf.okta;
    widgetConf.logo = '/logo.png';
    const widget = new OktaSignIn(widgetConf);

    widget.showSignInToGetTokens({
      el: widgetRef.current,
    }).then(onSuccess).catch(onError);

    return () => widget.remove();
  }, [conf, onSuccess, onError]);

  return (<div ref={widgetRef} />);
};

export default OktaSignInWidget;

